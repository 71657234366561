const getApiKey = () => {
    if (process.env.NODE_ENV === 'production') {
        return process.env.REACT_APP_API_KEY || 'chave_producao_default';
    } else {
        return process.env.REACT_APP_API_KEY || 'chave_desenvolvimento_default';
    }
};

const getTargetSetupProxy = () => {
    if (process.env.NODE_ENV === 'production') {
        return process.env.SETUP_PROXY_TARGET || 'chave_producao_default';
    } else {
        return process.env.SETUP_PROXY_TARGET || 'chave_desenvolvimento_default';
    }
};

const config = {
    getApiKey,
    getTargetSetupProxy,
}

export default config;