import React from 'react';
import AdminCards from '../admin/AdminCards'
export default function Backoffice() {

    return (
        <>
            <AdminCards />
        </>
    );

} 