import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {

    var details = (props.details !== null && props.details !== undefined) ? (<h6 className='cards__item__details'>{props.details}</h6>) : '';
    var price = (props.price !== null && props.price !== undefined) ? (<h5 className='cards__item__price'>{props.price}</h5>) : '';
    var label = (props.label !== null && props.label !== undefined) ?
        (
            <figure className='cards__item__pic-wrap' data-category={props.label}>
                <img
                    className='cards__item__img'
                    alt='Imagem'
                    src={props.src}
                />
            </figure>
        )
        :
        (
            <figure className='cards__item__pic-no-label'>
                <img
                    className='cards__item__img'
                    alt='Imagem'
                    src={props.src}
                />
            </figure>
        );

    return (
        <>
            <li className='cards__item'>
                <Link className='cards__item__link' to={props.path}>
                    {label}
                    <div className='cards__item__info'>
                        <h4 className='cards__item__text'>{props.text}</h4>
                        {details}
                        {price}
                    </div>
                </Link>
            </li>
        </>
    );
}

export default CardItem;