import React, { useState } from 'react';
import '../styles/SearchBox.css';

const SearchDropdown = ({ label, options, selectedValue, onSelect }) => {
    const [fuelTypeDropdownOpen, setFuelTypeDropdownOpen] = useState(false);

    // Para a caixa de combust�vel
    const handleToggleFuelTypeDropdown = () => {
        setFuelTypeDropdownOpen(!fuelTypeDropdownOpen);
    };

    return (
        <div className='search-field'>
            <div className='search-dropdown' onClick={handleToggleFuelTypeDropdown}>
                <div className='dropdown-trigger'>
                    {selectedValue !== '' ? selectedValue : label}
                </div>
                {fuelTypeDropdownOpen && (
                    <div className='dropdown-options maxHeightDropdown'>
                        {options.map((option) => (
                            <div
                                key={option}
                                className='dropdown-option'
                                onClick={() => { onSelect(option); handleToggleFuelTypeDropdown(); }}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchDropdown;
