import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client'; // Altera��o aqui
import store from './stores/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// Adicione esta linha para modificar a meta tag charset do documento
document.querySelector('meta[charset]').setAttribute('charset', 'UTF-8');

const root = document.getElementById('root');
const rootContainer = createRoot(root);

rootContainer.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
