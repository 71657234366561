import React from 'react';
import '../../styles/Cards.css';
import CardItem from '../CardItem';
import { Route, Redirect } from 'react-router-dom';

function Cards() {
    return (
        <div className='cards'>
            <h1>Área de Gestão</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/static/mercedes-cla.png'
                            text='Adicionar novo anúncio'
                            path='/addcar'
                        />
                        <CardItem
                            src='images/static/bmw-135m.png'
                            text='Ver lista de anúncios'
                            path='/'
                        />
                        <CardItem
                            src='images/static/audi-a3.png'
                            text='Exportar anúncios'
                            label='Em desenvolvimento'
                            path='/'
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Cards;