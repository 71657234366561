import React from 'react';
import '../../styles/PolicyPage.css';

function Garantia() {
    return (
        <div className="policy-page">
            <div className="policy-content">

                <header>
                    <h1>EPP Automóveis - Compromisso com a Qualidade e Fiabilidade</h1>
                </header>

                <section>
                    <p>
                        Na EPP Automóveis, garantimos a excelência e um desempenho superior em todos os veículos do nosso catálogo.
                        Comprometemo-nos a proporcionar um padrão elevado de garantia, sustentado por um minucioso processo de avaliação
                        e certificação.
                    </p>
                </section>

                <section>
                    <h2>Detalhes da Garantia:</h2>
                    <p>
                        Proporcionamos uma garantia abrangente que varia de 12 a 18 meses, para assegurar tranquilidade aos nossos
                        clientes. Além disso, oferecemos a opção de prolongar essa garantia por um período ainda mais extenso.
                    </p>
                </section>

                <section>
                    <h2>Componentes Abrangidos:</h2>
                    <p>
                        Desde os órgãos internos do motor até aos sistemas elétricos e de climatização, cada veículo na EPP
                        Automóveis está protegido pela nossa garantia sólida. Os principais componentes incluem:
                    </p>
                    <ul>
                        <li><strong>Motor:</strong> Cobrimos os órgãos internos do bloco, como cilindros, pistões, bielas, e mais.
                            Excluímos apenas a correia de distribuição, a menos que haja comprovação da mudança conforme recomendado
                            pelo fabricante.</li>
                        <li><strong>Caixa de Velocidades Manual e Automática:</strong> Incluímos os órgãos internos essenciais,
                            proporcionando tranquilidade quanto ao desempenho da transmissão.</li>
                        <li><strong>Sistema de Direção e Travagem:</strong> Desde a caixa de direção até às pinças de travões,
                            garantimos o bom funcionamento desses sistemas vitais.</li>
                        <li><strong>Circuito Elétrico e Injeção:</strong> Motores de arranque, alternadores, sistemas de injeção e
                            outros componentes elétricos estão cobertos para garantir uma experiência de condução segura.</li>
                        <li><strong>Sistema de Climatização:</strong> Todos os elementos cruciais, como condensador, compressor A/C e
                            motor de ventilação, são incluídos na garantia para assegurar conforto em todas as condições
                            climáticas.</li>
                        <li><strong>Embraiagem, Sistema de Arrefecimento e Mais:</strong> Desde a embraiagem até ao sistema de
                            arrefecimento, cada componente vital é abrangido pela nossa garantia.</li>
                    </ul>
                </section>

                <section>
                    <p>
                        Na EPP Automóveis, a nossa missão é proporcionar não apenas veículos de alta qualidade, mas também a certeza
                        de uma experiência de condução segura e fiável. Convidamo-lo a explorar a nossa gama de automóveis
                        certificados, sustentada pela nossa garantia abrangente.
                    </p>
                </section>

            </div>
        </div>
    );
}

export default Garantia;