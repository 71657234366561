import React from 'react';
import '../styles/Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
                <p className='footer-subscription-heading'>
                    { 'Visite-nos e descubra uma vasta seleção de carros novos e usados.'}
                </p>

                <p className='footer-subscription-text'>
                    {'Ao usar o nosso website, você está a concordar com o uso de cookies de acordo com a nossa política de cookies.'}
                </p>

            </section>
            <div className='footer-links'>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h2> {'Contactos'} </h2>
                        <Link to='/Contactos'>Contactos</Link>
                        <Link to='/Localizacao'>Localização</Link>
                    </div>
                    <div className='footer-link-items'>
                        <h2> {'Informações'} </h2>
                        <Link to='/CentroArbitrario'>Centro de arbitragem</Link>
                        <Link to='/Garantia'>Garantias</Link>
                        <Link to='/CentroArbitrario'>Intermediação de Crédito</Link>
                    </div>
                    <div className='footer-link-items'>
                        <h2> {'Informações Legais'} </h2>
                        <Link to='https://www.livroreclamacoes.pt/Inicio/'>Livro de reclamações</Link>
                        <Link to='/PrivacyPolicy'>Política de privacidade</Link>
                    </div>
                </div>

            </div>
            <section className='social-media'>
                <div className='social-media-wrap'>
                    <div className='footer-logo'>
                        <Link to='/' className='social-logo'>
                            {'EPP Automóveis'}
                        </Link>
                    </div>
                    <small className='website-rights'> {'EPP Automóveis | Copyright © 2023'} </small>
                    <div className='social-icons'>
                        <Link
                            className='social-icon-link facebook'
                            to='https://www.facebook.com/profile.php?id=100057307575529'
                            target='_blank'
                            aria-label='Facebook'
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" fill="#ffff"/></svg>
                        </Link>
                        <Link
                            className='social-icon-link messenger'
                            to='https://www.facebook.com/messages/t/116664269784831'
                            target='_blank'
                            aria-label='Messenger'
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 0 0 122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 0 1-53.91 9.93l-58.08-43.47a15 15 0 0 0-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0 1 53.91-9.93l58.06 43.46a15 15 0 0 0 18 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z" fill="#ffff" /></svg>
                        </Link>
                        <Link
                            className='social-icon-link whatsapp'
                            to='https://api.whatsapp.com/send?phone=%2B351935561504&app=facebook&entry_point=page_cta'
                            target='_blank'
                            aria-label='Whatsapp'
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" fill="#ffff" /></svg>
                        </Link>
                        <Link
                            className='social-icon-link email'
                            to='mailto:eppautomoveis@gmail.com'
                            target='_blank'
                            aria-label='Email'
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" fill="#ffff" /></svg>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Footer;