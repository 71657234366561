// SuccessFailureMessage.jsx
import React from 'react';

const SuccessFailureMessage = ({ success }) => {
    return (
        <>
            {success !== null && (
                <div className="row justify-content-center">
                    <div className="col-md-3">
                        <div className={`alert ${success ? 'alert-success' : 'alert-danger'} text-center`} role="alert">
                            {success ? 'Pedido executado com sucesso!' : 'Falha ao executar o pedido.'}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SuccessFailureMessage;
