import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { saveSortingOrderToBackend, getPhotos } from '../../../stores/apiService.js'; // Adjust the import path accordingly
import '../../../styles/UploadPhotos.css';
import SuccessFailureMessage from '../GenericComponents/SuccessFailureMessage';

const ChangePhotoOrder = () => {
    var images = [];
    const { advertId } = useParams();
    const [sortedPhotos, setSortedPhotos] = useState([]);
    const [saveSuccess, setSaveSuccess] = useState(null); // null: não mostrando, true: sucesso, false: falha

    useEffect(() => {
        const fetchData = async () => {
            try {
                var items = await getPhotos(advertId);

                for (var i = 0; i < items.length; i++) {
                    images.push({
                        id: items[i].photoId,
                        url: '../images/' + items[i].photoId + '.jpeg',
                    });
                }
                setSortedPhotos(images);
            } catch (error) {
                console.error('Error fetching photos:', error);
            }
        };

        fetchData();
    }, [advertId]);

    const [draggingIndex, setDraggingIndex] = useState(null);
    const [overIndex, setOverIndex] = useState(null);

    const handleDragEnd = (dragIndex, dropIndex) => {
        const newPhotos = Array.from(sortedPhotos);
        const [removed] = newPhotos.splice(dragIndex, 1);
        newPhotos.splice(dropIndex, 0, removed);
        setSortedPhotos(newPhotos);
    };

    const onDragStart = (index) => {
        setDraggingIndex(index);
    };

    const onDragOver = (index) => {
        setOverIndex(index);
    };

    const onDragEnd = () => {
        handleDragEnd(draggingIndex, overIndex);
        setDraggingIndex(null);
        setOverIndex(null);
    };

    const saveSortingOrder = async () => {
        try {
            const sortedPhotoIds = sortedPhotos.map(photo => photo.id);
            await saveSortingOrderToBackend(sortedPhotoIds);
            setSaveSuccess(true);
            //console.log("sucesso na ordenação");
        } catch (error) {
            //console.log("falha na ordenação");
            setSaveSuccess(false);
            throw error;
        }
    };

    return (
        <div className="container">
            <h1 className="title">Ordenar Fotos do Anúncio</h1>
            <div className="row justify-content-center">
                <SuccessFailureMessage success={saveSuccess} />
                <div className="col-md-12">
                    <div style={thumbnailContainerStyles}>
                        {sortedPhotos.map((photo, index) => (
                            <div
                                key={photo.id}
                                style={thumbnailStyles}
                                draggable
                                onDragStart={() => onDragStart(index)}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    onDragOver(index);
                                }}
                                onDragEnd={onDragEnd}
                            >
                                <img
                                    src={photo.url}
                                    alt={photo.name}
                                    style={thumbnailImageStyles}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="row justify-content-center">
                        <div className="text-center col-md-6">
                            <div className="row">
                                <button onClick={saveSortingOrder} className="btn btn-primary btn-lg mb-3">Salvar Ordem</button>
                            </div>
                            <div className="row">
                                <Link to={`/UpdatePhotos/${advertId}`} className="btn btn-secondary btn-lg">Voltar</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const thumbnailContainerStyles = {
    display: 'flex',
    flexWrap: 'wrap',
};

const thumbnailStyles = {
    width: '150px',
    margin: '8px',
    border: '1px solid #ccc',
    padding: '4px',
    borderRadius: '4px',
};

const thumbnailImageStyles = {
    maxWidth: '100%',
    maxHeight: '100%',
    cursor: 'pointer',
};

export default ChangePhotoOrder;
