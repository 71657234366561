import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAdverts } from '../stores/apiService';
import { Dropdown } from 'react-bootstrap';
import SearchDropdown from './SearchDropdown';
import CardItem from './CardItem';
import '../styles/SearchBox.css';
import '../styles/Cards.css';
import '../styles/Dropdown.css';

function Cards() {

    const adverts = useSelector((state) => state.adverts);
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState('Ordenar por...');
    const [loading, setLoading] = useState(true);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const options = [
        'Marca A - Z', 'Marca Z - A', 'Preço mais alto', 'Preço mais baixo', 'Quilometragem mais alta', 'Quilometragem mais baixa', 'Mais recente', 'Mais antigo'
    ];

    const [selectedFuelType, setSelectedFuelType] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [minKms, setMinKms] = useState('');
    const [maxKms, setMaxKms] = useState('');
    const [maxYear, minYear] = useState('');
    const [selectedMaker, setSelectedMaker] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [filteredAdverts, setFilteredAdverts] = useState([]);
    const [modelsFilter, setModelsFilter] = useState({});

    const [showInputs, setShowInputs] = useState(false);

    const FuelTypeName = {
        0: "Gásoleo",
        1: "Gasolina",
        2: "Elétrico",
        3: "Híbrido Plug-In",
        4: "Híbrido",
        5: "GPL"
    }

    const handleSetSelectedMaker = (maker) => {
        setSelectedMaker(maker);
        setSelectedModel('');

        // Verifique se há anúncios correspondentes à marca selecionada
        const filteredByMaker = adverts.filter((item) => item.car.maker === maker);

        // Obtenha os modelos únicos correspondentes à marca selecionada
        const modelsList = Array.from(new Set(filteredByMaker.map((item) => item.car.model)));

        // Atualize o estado modelsFilter com os modelos correspondentes à marca selecionada
        setModelsFilter(prevState => ({
            ...prevState,
            [maker]: modelsList
        }));
    };

    // Este useEffect será acionado sempre que adverts ou selectedOption forem alterados
    useEffect(() => {
        handleFilterSubmit();
    }, [adverts, selectedOption]);

    // Este useEffect será acionado apenas uma vez, quando o componente for montado
    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchAdverts(dispatch);
                setLoading(false);
            } catch (error) {
                console.error("Erro ao buscar anúncios:", error);
            }
        };

        fetchData();
    }, [dispatch]);

    const handleFilterButtonClick = () => {
        setShowInputs(true);
        handleFilterSubmit();
    }

    const MakersFilter = Array.from(
        new Set(adverts.map((item) => item.car.maker))
    );

    const handleFilterSubmit = () => {
        let filtered = adverts.slice();

        if (minPrice !== '') {
            filtered = filtered.filter((advert) => advert.advert.price >= minPrice);
        }

        if (maxPrice !== '') {
            filtered = filtered.filter((advert) => advert.advert.price <= maxPrice);
        }

        if (minKms !== '' || minKms > maxKms) {
            filtered = filtered.filter((advert) => advert.advert.mileage >= minKms);
        }

        if (maxKms !== '' || maxKms < minKms) {
            filtered = filtered.filter((advert) => advert.advert.mileage <= maxKms);
        }

        if (selectedFuelType !== '') {
            filtered = filtered.filter((advert) => advert.car.fuelType === parseInt(selectedFuelType, 10));
        }

        if (selectedMaker !== '') {
            filtered = filtered.filter((advert) => advert.car.maker === selectedMaker);
        }

        if (selectedModel !== '') {
            filtered = filtered.filter((advert) => advert.car.model === selectedModel);
        }

        switch (selectedOption) {
            case 'Marca A - Z':
                filtered.sort((a, b) => a.car.maker.localeCompare(b.car.maker));
                //console.log('Marca A - Z');
                break;
            case 'Marca Z - A':
                filtered.sort((a, b) => b.car.maker.localeCompare(a.car.maker));
                //console.log('Marca Z - A');
                break;
            case 'Preço mais alto':
                filtered.sort((a, b) => b.advert.price - a.advert.price);
                //console.log('Preço mais alto');
                break;
            case 'Preço mais baixo':
                filtered.sort((a, b) => a.advert.price - b.advert.price);
                //console.log('Preço mais baixo');
                break;
            case 'Quilometragem mais alta':
                filtered.sort((a, b) => b.advert.mileage - a.advert.mileage);
                //console.log('Quilometragem mais alta');
                break;
            case 'Quilometragem mais baixa':
                filtered.sort((a, b) => a.advert.mileage - b.advert.mileage);
                //console.log('Quilometragem mais baixa');
                break;
            case 'Mais recente':
                filtered.sort((a, b) => b.advert.year - a.advert.year);
                //console.log('Mais recente');
                break;
            case 'Mais antigo':
                filtered.sort((a, b) => a.advert.year - b.advert.year);
                //console.log('Mais antigo');
                break;
            case 'Ordenar por...':
                //nothing happens
                //console.log('Ordenar por...');
                break;
            default:
                break;
                //console.log("default!!!");
            //nothing happens

        }

        setFilteredAdverts(filtered);
    };

    let currentRowItems = [];

    for (let index = 0; index < filteredAdverts.length; index += 3) {
        const advert_first = filteredAdverts[index];
        const advert_sec = filteredAdverts[index + 1];
        const advert_third = filteredAdverts[index + 2];

        if (advert_third != null) {
            currentRowItems.push(
                <ul className='cards__items'>
                    <CardItem
                        src={'images/' + advert_first.advert.firstPhoto + '.jpeg'}
                        text={advert_first.car.maker + ' ' + advert_first.car.model}
                        price={advert_first.advert.price === 0 ? 'Sob consulta!' : advert_first.advert.price === -1 ? 'Vendido!' : advert_first.advert.price + ' €'}
                        details={FuelTypeName[advert_first.car.fuelType] + ' - ' + advert_first.advert.year + ' - ' + advert_first.advert.mileage + ' kms'}
                        label={advert_first.status}
                        path={'/Car/' + advert_first.advert.id}
                    />
                    <CardItem
                        src={'images/' + advert_sec.advert.firstPhoto + '.jpeg'}
                        text={advert_sec.car.maker + ' ' + advert_sec.car.model}
                        price={advert_sec.advert.price === 0 ? 'Sob consulta!' : advert_sec.advert.price === -1 ? 'Vendido!' : advert_sec.advert.price + ' €'}
                        details={FuelTypeName[advert_sec.car.fuelType] + ' - ' + advert_sec.advert.year + ' - ' + advert_sec.advert.mileage + ' kms'}
                        label={advert_sec.status}
                        path={'/Car/' + advert_sec.advert.id}
                    />
                    <CardItem
                        src={'images/' + advert_third.advert.firstPhoto + '.jpeg'}
                        text={advert_third.car.maker + ' ' + advert_third.car.model}
                        price={advert_third.advert.price === 0 ? 'Sob consulta!' : advert_third.advert.price === -1 ? 'Vendido!' : advert_third.advert.price + ' €'}
                        details={FuelTypeName[advert_third.car.fuelType] + ' - ' + advert_third.advert.year + ' - ' + advert_third.advert.mileage + ' kms'}
                        label={advert_third.status}
                        path={'/Car/' + advert_third.advert.id}
                    />
                </ul>
            );
        }
        else {
            if (advert_sec != null) {
                currentRowItems.push(
                    <ul className='cards__items'>
                        <CardItem
                            src={'images/' + advert_first.advert.firstPhoto + '.jpeg'}
                            text={advert_first.car.maker + ' ' + advert_first.car.model}
                            price={advert_first.advert.price === 0 ? 'Sob consulta!' : advert_first.advert.price === -1 ? 'Vendido!' : advert_first.advert.price + ' €'}
                            details={FuelTypeName[advert_first.car.fuelType] + ' - ' + advert_first.advert.year + ' - ' + advert_first.advert.mileage + ' kms'}
                            label={advert_first.status}
                            path={'/Car/' + advert_first.advert.id}
                        />
                        <CardItem
                            src={'images/' + advert_sec.advert.firstPhoto + '.jpeg'}
                            text={advert_sec.car.maker + ' ' + advert_sec.car.model}
                            price={advert_sec.advert.price === 0 ? 'Sob consulta!' : advert_sec.advert.price === -1 ? 'Vendido!' : advert_sec.advert.price + ' €'}
                            details={FuelTypeName[advert_sec.car.fuelType] + ' - ' + advert_sec.advert.year + ' - ' + advert_sec.advert.mileage + ' kms'}
                            label={advert_sec.status}
                            path={'/Car/' + advert_sec.advert.id}
                        />
                    </ul>
                );
            } else {
                currentRowItems.push(
                    <ul className='cards__items'>
                        <CardItem
                            src={'images/' + advert_first.advert.firstPhoto + '.jpeg'}
                            text={advert_first.car.maker + ' ' + advert_first.car.model}
                            price={advert_first.advert.price === 0 ? 'Sob consulta!' : advert_first.advert.price === -1 ? 'Vendido!' : advert_first.advert.price + ' €'}
                            details={FuelTypeName[advert_first.car.fuelType] + ' - ' + advert_first.advert.year + ' - ' + advert_first.advert.mileage + ' kms'}
                            label={advert_first.status}
                            path={'/Car/' + advert_first.advert.id}
                        />
                    </ul>
                );
            }
        }
    }


    return (
        <div className='cards'>

            <div className='search-box-horizontal'>
                <div className='search-inputs-wrapper'>
                    <div className={`search-inputs-horizontal ${showInputs ? '' : 'hidden'}`}>
                        <div className='left-half-search-field'>
                            <input type="number" value={minPrice} onChange={(e) => setMinPrice(e.target.value)} placeholder="Preço Min." />
                        </div>
                        <div className='right-half-search-field'>
                            <input type="number" value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} placeholder="Preço Max." />
                        </div>

                        <div className='left-half-search-field'>
                            <input type="number" value={minKms} onChange={(e) => setMinKms(e.target.value)} placeholder="Km. Min." />
                        </div>
                        <div className='right-half-search-field'>
                            <input type="number" value={maxKms} onChange={(e) => setMaxKms(e.target.value)} placeholder="Km. Max." />
                        </div>

                        <div className='left-half-search-field'>
                            <input type="number" value={minYear} onChange={(e) => setMinKms(e.target.value)} placeholder="Ano de" />
                        </div>
                        <div className='right-half-search-field'>
                            <input type="number" value={maxYear} onChange={(e) => setMaxKms(e.target.value)} placeholder="Ano até" />
                        </div>
                    </div>
                    <div className={`search-inputs-horizontal ${showInputs ? '' : 'hidden'}`}>

                        <SearchDropdown
                            label='Combustível'
                            options={Object.values(FuelTypeName)}
                            selectedValue={selectedFuelType !== '' ? FuelTypeName[selectedFuelType] : 'Combustível'}
                            onSelect={(fuelType) => setSelectedFuelType(Object.keys(FuelTypeName).find(key => FuelTypeName[key] === fuelType))}
                        />

                        <SearchDropdown
                            label='Marca'
                            options={MakersFilter}
                            selectedValue={selectedMaker}
                            onSelect={(maker) => handleSetSelectedMaker(maker)}
                        />

                        <SearchDropdown
                            label='Modelo'
                            options={selectedMaker && modelsFilter[selectedMaker] ? modelsFilter[selectedMaker] : ['Nenhum modelo disponível']}
                            selectedValue={selectedModel}
                            onSelect={(model) => setSelectedModel(model)}
                        />

                    </div>
                    <button className='search-button-horizontal' onClick={handleFilterButtonClick}>
                        <i className='search'>{showInputs ? 'Pesquisar     ' : 'Filtrar     '}</i>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" fill="#ffffff" />
                        </svg>
                    </button>
                </div>

                <div className="dropdown-container">
                    <Dropdown alignRight className="ml-auto">
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            {selectedOption}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {options.map((option, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => handleOptionClick(option)}
                                >
                                    {option}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <ul className='cards__container'>
                <div className='cards__wrapper'>
                    {currentRowItems}
                </div>
            </ul>

        </div>
    );
}

export default Cards;