import React from 'react';
import '../../styles/Cards.css';
import CardItem from '../CardItem';
import { Route, Redirect } from 'react-router-dom';

function Servicos() {
    return (
        <div className='cards'>
            <h1>Serviços</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/static/loan.png'
                            text='Intermediação de crédito'
                            path='/CentroArbitrario'
                        />
                        <CardItem
                            src='images/static/warranty.png'
                            text='Garantia'
                            path='/Garantia'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/static/contact-mail.png'
                            text='Contactos'
                            path='/Contactos'
                        />
                        <CardItem
                            src='images/static/store.png'
                            text='Localização'
                            path='/Localizacao'
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Servicos;