import React from 'react';
import '../../styles/PolicyPage.css';

function CentroArbitrario() {
    return (
        <div className="policy-page">
            <div className="policy-content">

                <h1>Centro de Arbitragem do Sector Automóvel</h1>

                <br/>

                <p>
                    Em caso de litígio o consumidor pode recorrer ao <b>Centro Nacional de informação e Arbitragem de conflitos de consumo</b>, com site em <a href="https://www.cniacc.pt/pt">https://www.cniacc.pt/pt</a> e sede na Rua D. Afonso Henriques, 1, 4700-030 Braga.
                </p>

                <p>
                    Para atualizações e mais informações, consulte o Portal do Consumidor em <a href="https://www.consumidor.gov.pt/">https://www.consumidor.gov.pt/</a> (ao abrigo do artigo 18.º da Lei n.º 144/2015, de 8 de setembro)
                </p>

                <br />

                <br />

                <h2>Informação relativa à atividade de intermediário de crédito</h2>

                <br />

                <p>
                    <b>Eurico José Prada Pires</b> com sede em <b>Avenida das Cantarias Nº 78, 5300-107, Bragança</b>, intermediário de crétido com o número de registo: <b>nº 0001110</b>
                </p>

                <p>
                    Contacto telefónico: <b>(+351) 935 561 504</b>
                </p>

                <p>
                    Email: <b>eppautomoveis@gmail.com</b>                
                </p>

                <br />
                
                <p>
                    O Stand <b>EPP Automóveis, Lda</b>, está registado como intermediário de crédito a Titulo Acessório no Banco de Portugal sob o <b>nº 0001110</b> – Registo disponível para consulta no portal do cliente bancário <a href="https://www.bportugal.pt/intermediariocreditofar/eurico-jose-prada-pires">https://www.bportugal.pt/intermediariocreditofar/eurico-jose-prada-pires</a>
                </p>

                <p>
                    Categoria: Intermediário de crédito a Titulo Acessório.
                </p>

                <p>
                    Mutuantes com quem mantêm contrato de vinculação:
                </p>
                <ul>
                    <li>
                        <p>
                            <b>321CRÉDITO</b>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>INSTITUIÇÃO FINANCEIRA DE CRÉDITO S.A.</b>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>COFIDIS</b>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>BICREDIT</b>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>SOCIEDADE FINANCEIRA DE CRÉDITO, S.A.</b>
                        </p>
                    </li>
                </ul>

                <p>
                    O intermediário de crédito <b>Eurico José Prada Pires</b> não exerce a atividade em regime de exclusividade relativamente a um mutuante.
                </p>

                <p>
                    Serviços de intermediação de crédito:
                </p>
                <ul>
                    <li>
                        Apresentação ou proposta de contratos de credito a consumidores
                    </li>
                    <li>
                        Assistencia a consumidores, mediante a realização de atos preparatórios ou de outros trabalhos de gestão pré-contratual relativamente a contratos de credito que não tenham sido por si apresentados ou propostos
                    </li>
                </ul>

                <p>
                    O Intermediário de crédito não presta serviços de consultoria.
                </p>

                {/*
                <p>
                    Contrato de seguro de responsabilidade civil profissional celebrado com:
                </p>
                <ul>
                    <li>
                        Entidade <b>"(nome da entidade p.e. Fidelidade, ...)"</b>
                    </li>
                    <li>
                        Apólice nº <b>"nº?"</b>, válida até: <b>"data?"</b>
                    </li>
                </ul>*/}

                <p>
                    O intermediário de crédito <b>Eurico José Prada Pires</b> não pode receber ou entregar quaisquer valores relacionados com a formação, a execução e o cumprimento antecipado dos contratos de crédito, nos termos do artigo 46º do Decreto-Lei 81-C/2017, de 07/07.
                </p>

                <p>
                    A atividade de intermediário de crédito do <b>Stand EPP Automóveis, Lda</b> está sujeita à supervisão do Banco de Portugal.
                </p>

                <p>
                    (Informação prestada em conformidade com o artigo 54.º do Decreto-Lei 81-C/2017, de 07 de julho)
                </p>

            </div>
        </div>
    );
}

export default CentroArbitrario;