import React from 'react';
import Cards from '../Cards';
export default function Products() {

    return (
        <>
            <Cards />
        </>
    );

} 