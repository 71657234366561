import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ExistingPhotosList from './ExistingPhotosList';
import '../../../styles/UploadPhotos.css';

const UpdatePhotos = () => {
    const { advertId } = useParams();
    //console.log("UpdatePhotos -> advertId: " + advertId);
    return (
        <div className="container">
            <h1 className="title text-center">Atualizar fotos do anúncio</h1>
            <ExistingPhotosList advertId={advertId} /> {/* Inclua ExistingPhotosList aqui */}
            <div className="row justify-content-center">
                <div className="text-center col-md-6">
                    <div className="row">
                        <Link to={`/add-remove/${advertId}`} className="btn btn-success btn-lg btn-block mb-3">Adicionar/Remover Fotos</Link>
                    </div>
                    <div className="row">
                        <Link to={`/change-photo-order/${advertId}`} className="btn btn-primary btn-lg btn-block mb-3">Alterar Ordem das Fotos</Link>
                    </div>
                    <div className="row">
                        <Link to={`/Car/${advertId}`} className="btn btn-secondary btn-lg">Voltar</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdatePhotos;
