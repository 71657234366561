import React from 'react';
import '../../styles/Local.css';

function Local() {

    return (
        <div className="container">
            <div className="container-title">
                <h1> Onde estamos?</h1>
            </div>
            <div className="container-title">
                <h3> Visite-nos! </h3>
            </div>

            <div className="div-map">
                <a href="https://maps.app.goo.gl/9zcRrsUVmmMejybt5" target="_blank" rel="noopener noreferrer">
                    <img src="images/static/map.png" alt="Map" />
                </a>
            </div>

            <div className="map-info">
                <p>
                    Av. das Cantarias 78, 5300-107 Bragança
                </p>
            </div>
        </div >
    );
}

export default Local;
