import React, { useEffect, useState, useRef } from 'react';
import '../styles/Cards.css';
import '../styles/SearchBox.css';
import CardItem from './CardItem';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDashBoardAdverts } from '../stores/apiService';
import ImageGallery from "react-image-gallery";

function CardsDashboard() {

    const adverts = useSelector((state) => state.adverts);
    const dispatch = useDispatch();

    const [photos, setPhotos] = useState([{}]);
    const [loading, setLoading] = useState(true);

    const FuelTypeName = {
        0: "Gásoleo",
        1: "Gasolina",
        2: "Elétrico",
        3: "Híbrido Plug-In",
        4: "Híbrido",
        5: "GPL"
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchDashBoardAdverts(dispatch);
                setLoading(false); // Marca o carregamento como concluído
            } catch (error) {
                console.error("Erro ao buscar anúncios:", error);
            }
        };

        fetchData(); // Chama a função assíncrona para buscar dados

    }, [dispatch]);

    useEffect(() => {
        // Mapeia as imagens apenas quando os dados estiverem disponíveis
        if (!loading) {
            const images = adverts.map((a) => ({
                original: '../images/' + a.advert.firstPhoto + '.jpeg',
                thumbnail: '../images/' + a.advert.firstPhoto + '.jpeg',
            }));

            setPhotos(images);
        }

    }, [adverts, loading]);


    if (loading) {
        return <div>Carregando...</div>;
    }

    let currentRowItems = [];

    for (let index = 0; index < adverts.length; index += 3) {
        const advert_first = adverts[index];
        const advert_sec = adverts[index + 1];
        const advert_third = adverts[index + 2];

        if (advert_third != null) {
            currentRowItems.push(
                <ul className='cards__items' key={index}>
                    <CardItem
                        src={'images/' + advert_first.advert.firstPhoto + '.jpeg'}
                        text={advert_first.car.maker + ' ' + advert_first.car.model}
                        price={advert_first.advert.price === 0 ? 'Sob consulta!' : advert_first.advert.price === -1 ? 'Vendido!' : advert_first.advert.price + ' €'}
                        details={FuelTypeName[advert_first.car.fuelType] + ' - ' + advert_first.advert.year + ' - ' + advert_first.advert.mileage + ' kms'}
                        label={advert_first.status}
                        path={'/Car/' + advert_first.advert.id}
                    />
                    <CardItem
                        src={'images/' + advert_sec.advert.firstPhoto + '.jpeg'}
                        text={advert_sec.car.maker + ' ' + advert_sec.car.model}
                        price={advert_sec.advert.price === 0 ? 'Sob consulta!' : advert_sec.advert.price === -1 ? 'Vendido!' : advert_sec.advert.price + ' €'}
                        details={FuelTypeName[advert_sec.car.fuelType] + ' - ' + advert_sec.advert.year + ' - ' + advert_sec.advert.mileage + ' kms'}
                        label={advert_sec.status}
                        path={'/Car/' + advert_sec.advert.id}
                    />
                    <CardItem
                        src={'images/' + advert_third.advert.firstPhoto + '.jpeg'}
                        text={advert_third.car.maker + ' ' + advert_third.car.model}
                        price={advert_third.advert.price === 0 ? 'Sob consulta!' : advert_third.advert.price === -1 ? 'Vendido!' : advert_third.advert.price + ' €'}
                        details={FuelTypeName[advert_third.car.fuelType] + ' - ' + advert_third.advert.year + ' - ' + advert_third.advert.mileage + ' kms'}
                        label={advert_third.status}
                        path={'/Car/' + advert_third.advert.id}
                    />
                </ul>
            );
        }
        else {
            if (advert_sec != null) {
                currentRowItems.push(
                    <ul className='cards__items' key={index}>
                        <CardItem
                            src={'images/' + advert_first.advert.firstPhoto + '.jpeg'}
                            text={advert_first.car.maker + ' ' + advert_first.car.model}
                            price={advert_first.advert.price === 0 ? 'Sob consulta!' : advert_first.advert.price === -1 ? 'Vendido!' : advert_first.advert.price + ' €'}
                            details={FuelTypeName[advert_first.car.fuelType] + ' - ' + advert_first.advert.year + ' - ' + advert_first.advert.mileage + ' kms'}
                            label={advert_first.status}
                            path={'/Car/' + advert_first.advert.id}
                        />
                        <CardItem
                            src={'images/' + advert_sec.advert.firstPhoto + '.jpeg'}
                            text={advert_sec.car.maker + ' ' + advert_sec.car.model}
                            price={advert_sec.advert.price === 0 ? 'Sob consulta!' : advert_sec.advert.price === -1 ? 'Vendido!' : advert_sec.advert.price + ' €'}
                            details={FuelTypeName[advert_sec.car.fuelType] + ' - ' + advert_sec.advert.year + ' - ' + advert_sec.advert.mileage + ' kms'}
                            label={advert_sec.status}
                            path={'/Car/' + advert_sec.advert.id}
                        />
                    </ul>
                );
            } else {
                currentRowItems.push(
                    <ul className='cards__items' key={index}>
                        <CardItem
                            src={'images/' + advert_first.advert.firstPhoto + '.jpeg'}
                            text={advert_first.car.maker + ' ' + advert_first.car.model}
                            price={advert_first.advert.price === 0 ? 'Sob consulta!' : advert_first.advert.price === -1 ? 'Vendido!' : advert_first.advert.price + ' €'}
                            details={FuelTypeName[advert_first.car.fuelType] + ' - ' + advert_first.advert.year + ' - ' + advert_first.advert.mileage + ' kms'}
                            label={advert_first.status}
                            path={'/Car/' + advert_first.advert.id}
                        />
                    </ul>
                );
            }
        }
    }

    return (
        <div>
            <div className='cards'>

                <ul className='cards__container'>
                    <div className='cards__wrapper'>
                        {currentRowItems}
                    </div>
                </ul>
            </div>
        </div>
    );
}

export default CardsDashboard;
