import React, { useState, useEffect } from 'react';
import { getPhotos } from '../../../stores/apiService.js'; // Importe a fun��o getPhotos
import '../../../styles/UploadPhotos.css';

const ExistingPhotosList = ({ advertId }) => {
    const [existingPhotos, setExistingPhotos] = useState([]);
    var images = [];

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                var items = await getPhotos(advertId);

                for (var i = 0; i < items.length; i++) {
                    images.push({
                        id: items[i].photoId,
                        url: '../images/' + items[i].photoId + '.jpeg',
                    });
                }
                setExistingPhotos(images);
            } catch (error) {
                console.error('Error fetching existing photos:', error);
            }
        };

        fetchPhotos();
    }, [advertId]);

    return (
        <div>
            <h2 className="subtitle">Fotos Existentes</h2>
            <div className="row">
                {existingPhotos.map(photo => (
                    <div key={photo.id} className="col-md-3 photo-container">
                        <div className="border border-dark rounded d-flex justify-content-center align-items-center mb-4">
                            <img src={photo.url} alt={photo.name} className="img-fluid" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExistingPhotosList;
