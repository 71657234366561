// apiService.js
import axios from 'axios';
import store from './store';
import { setAdverts } from './actions';
import config from '../config';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from './firebase.jsx';

const API_BASE_URL = config.getApiKey(); // Your API base URL

// Fun��o para obter o token JWT ap�s o login do usu�rio
const getJWTToken = () => {
    const auth = getAuth(app);
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken()
                    .then((token) => {
                        resolve(token);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject(new Error('Usu�rio n�o autenticado'));
            }
        });
    });
};

export const fetchAdverts = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/Advert`);
        const adverts = response.data;
        store.dispatch(setAdverts(adverts));
    } catch (error) {
        console.error('Error fetching adverts:', error);
    }
};

export const fetchDashBoardAdverts = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/Advert/DashboardList`);
        const adverts = response.data;
        store.dispatch(setAdverts(adverts));
    } catch (error) {
        console.error('Error fetching adverts:', error);
    }
};

export const fetchAdvertById = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/Advert/${id}`);
        const fullAdvert = response.data; // Assuming the API returns a single advert
        return fullAdvert; // Return the fetched advert
    } catch (error) {
        console.error('Error fetching advert by ID:', error);
        throw error; // Rethrow the error to handle in the component
    }
};

export const createAdvert = async (createCarAndAdvertModel) => {
    try {
        // Obtenha o token JWT
        const token = await getJWTToken();

        // Configure o cabe�alho de autoriza��o
        const authHeader = {
            Authorization: `Bearer ${token}`,
        };

        const response = await axios.post(`${API_BASE_URL}/Advert`, createCarAndAdvertModel, { headers: authHeader });

        if (response.status === 200) {
            const data = response.data;
            return data; // You can return the created advert data if needed
        } else {
            //console.error(`Unexpected response status: ${response.status}`);
            return null;
        }
    } catch (error) {
        //console.error('Error creating advert:', error);
        throw error;
    }
};

export const uploadPhotos = async (photos, id) => {
    try {
        const token = await getJWTToken();

        const authHeader = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
        };

        const formData = new FormData();

        photos.forEach((photo) => {
            formData.append('photos', photo);
        });

        //console.log('Requesting photo upload...'); // Log antes da solicita��o

        const response = await axios.post(`${API_BASE_URL}/Advert/upload-photos/${id}`, formData, {
            headers: authHeader
        });

        if (response.status === 200) {
            //console.log("create advert response: " + JSON.stringify(response.data));
            const data = response.data;
            return data; // You can return the created advert data if needed
        } else {
            //console.error(`Unexpected response status: ${response.status}`);
            return null;
        }
    } catch (error) {
        //console.error('Error creating advert:', error);
        throw error;
    }
};

export const archiveAdverts = async (id) => {
    try {
        const token = await getJWTToken();

        // Configure o cabe�alho de autoriza��o
        const authHeader = {
            'Authorization': `Bearer ${token}`
        };

        const response = await axios.put(`${API_BASE_URL}/Advert/archive-advert/${id}`, null ,{ headers: authHeader });

        if (response.status === 200) {
            const data = response.data;
            return data;
        } else {
            return null;
        }

    } catch (error) {
        throw error; // Rethrow the error to handle in the component
    }
};

export const saveSortingOrderToBackend = async (sortedPhotoIds) => {
    try {
        // Obtenha o token JWT
        const token = await getJWTToken();

        // Configure o cabe�alho de autoriza��o
        const authHeader = {
            Authorization: `Bearer ${token}`,
        };
        const response = await axios.post(`${API_BASE_URL}/Advert/save-sorting-order`, sortedPhotoIds, { headers: authHeader });

        if (!response.status === 200) {
            throw new Error('Failed to update photos sort id');
        }

        return response.data;
    } catch (error) {
        throw new Error('Error updating photos sort id: ' + error.message);
    }
};

export const updateAdvert = async (createCarAndAdvertModel) => {
    try {
        // Obtenha o token JWT
        const token = await getJWTToken();

        // Configure o cabe�alho de autoriza��o
        const authHeader = {
            Authorization: `Bearer ${token}`,
        };

        const response = await axios.put(`${API_BASE_URL}/Advert/update-advert`, createCarAndAdvertModel, { headers: authHeader });

        if (response.status === 200) {
            const data = response.data;
            return data; // You can return the created advert data if needed
        } else {
            return null; // Return null or handle the error as needed
        }
    } catch (error) {
        throw error; // Rethrow the error to handle in the component
    }
};

export const getPhotos = async (id) => {
    try {
        const token = await getJWTToken();

        // Configure o cabe�alho de autoriza��o
        const authHeader = {
            Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(`${API_BASE_URL}/Advert/GetPhotos/${id}`, { headers: authHeader });
        const photos = response.data; // Assuming the API returns a single advert
        return photos; // Return the fetched advert
    } catch (error) {
        throw error; // Rethrow the error to handle in the component
    }
};

export const removePhotos = async (updatePhotosModel) => {
    try {
        // Obtenha o token JWT
        const token = await getJWTToken();

        // Configure o cabe�alho de autoriza��o
        const authHeader = {
            Authorization: `Bearer ${token}`,
        };

        const response = await axios.post(`${API_BASE_URL}/Advert/RemovePhotos`, updatePhotosModel, { headers: authHeader });

        if (response.status === 200) {
            const data = response.data;
            return data; // You can return the created advert data if needed
        } else {
            return null; // Return null or handle the error as needed
        }
    } catch (error) {
        throw error; // Rethrow the error to handle in the component
    }
};

export const exportAdToFb = async (id) => {
    try {
        // Obtenha o token JWT
        const token = await getJWTToken();

        // Configure o cabe�alho de autoriza��o
        const authHeader = {
            Authorization: `Bearer ${token}`,
        };
        const response = await axios.post(`${API_BASE_URL}/Advert/export-fb/${id}`, id, { headers: authHeader });

        if (response.status === 200) {
            const data = response.data;
            return data; // You can return the created advert data if needed
        } else {
            return null; // Return null or handle the error as needed
        }
    } catch (error) {
        throw error; // Rethrow the error to handle in the component
    }
};