import React from 'react';
import '../../styles/Cards.css';
import CardItem from '../CardItem';
import { Route, Redirect } from 'react-router-dom';

function Contacts() {
    return (
        <div className='cards'>
            <h1>Contactos</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/static/telephone.png'
                            label='Telemóvel'
                            text='(+351) 935 561 504'
                        />
                        <CardItem
                            src='images/static/email.png'
                            label='Email'
                            text='Envie por aqui!'
                            path='mailto:eppautomoveis@gmail.com'
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/static/messenger.png'
                            label='Messenger'
                            text='Aceda por aqui!'
                            path='https://www.facebook.com/messages/t/116664269784831'

                        />
                        <CardItem
                            src='images/static/whatsapp.png'
                            label='Whatsapp'
                            text='Aceda por aqui!'
                            path='https://api.whatsapp.com/send?phone=%2B351935561504&app=facebook&entry_point=page_cta'
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Contacts;