import React, { useState } from 'react';
import { createAdvert, uploadPhotos, exportAdToFb } from './../../stores/apiService.js'; // Adjust the import path accordingly
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/SharedLib.css';
import '../../styles/AddCar.css';

const AddCar = () => {
    const [maker, setMaker] = useState('');
    const [model, setModel] = useState('');
    const [price, setPrice] = useState('');
    const [carFeatures, setCarFeatures] = useState('');
    const [category, setCategory] = useState('');
    const [cc, setCc] = useState('');
    const [hp, setHp] = useState('');
    const [color, setColor] = useState('');
    const [description, setDescription] = useState('');
    const [mileage, setMileage] = useState('');
    const [year, setYear] = useState('');
    const [imageCount, setImageCount] = useState('0');
    const [selectedFuelType, setSelectedFuelType] = useState(6);

    const [selectedFiles, setSelectedFiles] = useState([]);

    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const [advertId, setAdvertId] = useState('');

    const FuelTypeName = {
        0: "Gásoleo",
        1: "Gasolina",
        2: "Elétrico",
        3: "Híbrido Plug-In",
        4: "Híbrido",
        5: "GPL",
        6: "Selecionar"
    }


    const handleFuelTypeChange = (event) => {
        setSelectedFuelType(parseInt(event.target.value, 10));
    };

    const handlePhoto = (files) => {
        try {
            const selectedFiles = Array.from(files);
            setImageCount(selectedFiles.length);
            setSelectedFiles(selectedFiles);
        }
        catch (error) {
            console.error('Handle photos: ', error);
        }
    };

    const handlePhotoUpload = (selectedFiles, id) => {
        uploadPhotos(selectedFiles, id);
    }

    const handleReset = () => {
        setMessage('');
        setShowMessage(false);
    };

    const handleExportFbClick = async () => {
        setMessage('Aguarde...');
        try {
            if (advertId !== null) {
                const exportToFbAdvert = await exportAdToFb(advertId);
                if (exportToFbAdvert === "Postagem publicada com sucesso!") {
                    setMessage(exportToFbAdvert);
                }
                else {
                    setMessage('Erro ao exportar anúncio!');
                }
            }
        }
        catch (error) {
            setMessage('Erro ao exportar anúncio!');
            throw error;
        }
        setShowMessage(true);
    }

    const handleRedirect = () => {
        // Perform redirection to the car list page (you'll need React Router or similar)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // You can handle form submission here, including photo upload and export

        try {
            const createCarAndAdvertModel = {
                Car: {
                    Maker: maker,
                    Model: model,
                    CC: cc,
                    HP: hp,
                    CarCategory: category,
                    FuelType: selectedFuelType
                },
                Advert: {
                    //CarId: 0, // You can set this to 0 initially; it will be updated by the server
                    Price: price,
                    Status: 1,
                    CarFeatures: carFeatures,
                    Description: description,
                    ImageCount: imageCount, // You can set this as an empty string since the URL will be generated by the server
                    //CarFeatures: carFeatures,
                    Year: year,
                    Mileage: mileage,
                    Color: color,
                }
            };

            const createdAdvert = await createAdvert(createCarAndAdvertModel);

            const uploadPhotosAdvert = await handlePhotoUpload(selectedFiles, 0);

            setAdvertId(createdAdvert.advertId)

            setMessage('Anúncio criado com sucesso!');
        } catch (error) {
            setMessage('Erro ao adicionar anúncio!');
        }

        setShowMessage(true);
    };

    return (
        <div className="container mt-5">
            <div className="header row">
                <h1 className="header-title" > {"Adicionar novo anúncio"} </h1>
            </div>
            <div className="advert-form">
                {!showMessage && (
                    <form onSubmit={handleSubmit}>
                        <div className="div-form-wrapper">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="maker" className="form-label">Marca</label>
                                        <input type="text" className="form-control" id="maker" value={maker} onChange={(e) => setMaker(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="model" className="form-label">Modelo</label>
                                        <input type="text" className="form-control" id="model" value={model} onChange={(e) => setModel(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="cc" className="form-label">{"Cilindrada"}</label>
                                        <input type="text" className="form-control" id="cc" value={cc} onChange={(e) => setCc(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="hp" className="form-label"> {"Potência"} </label>
                                        <input type="text" className="form-control" id="hp" value={hp} onChange={(e) => setHp(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="fuelTypeDropdown">Tipo de Combustível</label>
                                        <select
                                            id="fuelTypeDropdown"
                                            name="fuelType"
                                            value={selectedFuelType}
                                            onChange={handleFuelTypeChange}
                                            className="form-control"
                                        >
                                            {Object.keys(FuelTypeName).map((key) => (
                                                <option key={key} value={key}>
                                                    {FuelTypeName[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="category" className="form-label"> {"Tipo de carro"} </label>
                                        <input type="text" className="form-control" id="category" value={category} onChange={(e) => setCategory(e.target.value)} />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="price" className="form-label"> {"Preço"} </label>
                                        <input type="text" className="form-control" id="price" value={price} onChange={(e) => setPrice(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="carFeatures" className="form-label">Extras</label>
                                        <input type="text" className="form-control" id="carFeatures" value={carFeatures} onChange={(e) => setCarFeatures(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="description" className="form-label">Descrição</label>
                                        <input type="text" className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="year" className="form-label"> {"Ano"} </label>
                                        <input type="text" className="form-control" id="year" value={year} onChange={(e) => setYear(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="mileage" className="form-label"> {"Quilimetragem"} </label>
                                        <input type="text" className="form-control" id="mileage" value={mileage} onChange={(e) => setMileage(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="color" className="form-label"> {"Cor"} </label>
                                        <input type="text" className="form-control" id="color" value={color} onChange={(e) => setColor(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="photos" className="form-label">Fotos</label>
                            <input type="file" className="form-control" id="photos" multiple onChange={(e) => handlePhoto(e.target.files)} />
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form >
                )}
            </div>
            <div className="advert-form-result">
                {showMessage && (
                    <div className="result">
                        <div className={`alert ${message.toLowerCase().includes('sucesso') ? 'alert-success' : 'alert-danger'}`}>
                            {message}
                        </div>
                        <div className="centered-buttons">
                            <button className="blue-button" onClick={handleReset}>Carregar mais anúncios</button>
                            <Link to="/products">
                                <button className="green-button" onClick={handleRedirect}>Ver lista de anúncios</button>
                            </Link>
                            <button className="blue-button" onClick={handleExportFbClick}>Exportar anúncio</button>
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
};

export default AddCar;