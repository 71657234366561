import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { app, analytics } from '../stores/firebase.jsx';

const auth = getAuth(app);
function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const [user, setUser] = useState(null);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
        // Set up an observer to listen for changes in authentication state
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in.
                setUser(user);
            } else {
                // User is signed out.
                setUser(null);
            }
        });

        // Clean up the observer when the component unmounts
        return () => unsubscribe();
    }, []);

    //useEffect(() => {
    //}, []);

    const handleLogout = () => {
        closeMobileMenu();

        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            console.error('Erro de logout:', error);

        });
    }

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <img src={'../images/static/epp.svg'} alt='EPP Automóveis' className='logo-svg' />
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} style={{ color: '#990000' }} />
                    </div>

                    <ul className={user ?
                        (click ? 'nav-menu-admin active' : 'nav-menu-admin') :
                        (click ? 'nav-menu active' : 'nav-menu')}>

                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link
                                to='/products'
                                className='nav-links'
                                onClick={closeMobileMenu}
                            >
                                Viaturas
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link
                                to='/Servicos'
                                className='nav-links'
                                onClick={closeMobileMenu}
                            >
                                Serviços
                            </Link>
                        </li>

                        {user ?
                            <li className='nav-item'>
                                <Link
                                    to='/backoffice'
                                    className='nav-links'
                                    onClick={closeMobileMenu}
                                >
                                    Admin
                                </Link>
                            </li> : ''
                        }
                        {user ?
                            <li className='nav-item'>
                                <Link
                                    to='/'
                                    className='nav-links'
                                    onClick={handleLogout}
                                >
                                    Logout
                                </Link>
                            </li> : ''
                        }
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;