import React, { useState } from 'react';
import '../../styles/Login.css';
import { Link } from 'react-router-dom';
import { app, analytics } from '../../stores/firebase.jsx';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

function Login() {

    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({ username: '', password: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    const handleLogin = async (e) => {
        e.preventDefault()
        try {
            const auth = getAuth(app); // Get the auth object from the initialized app
            const userCredential = await signInWithEmailAndPassword(auth, credentials.username, credentials.password);
            const user = userCredential.user;
            //console.log('Usu�rio logado:', user);
            navigate('/backoffice');
        } catch (error) {
            //console.error('Erro de login:', error);
            throw error;
        }
    };

return (
    <>
        <div className="login-body login-container">
            <div className="login-box">
                <h2>Login</h2>
                <div className="div-form-wrapper">
                    <form onSubmit={handleLogin}>
                        <input
                            type="text"
                            placeholder="Username"
                            name="username"
                            value={credentials.username}
                            onChange={handleChange}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={credentials.password}
                            onChange={handleChange}
                        />
                        <button className="button-login" type="submit">Login</button>
                    </form>
                </div>
            </div>
        </div>
    </>
);
}

export default Login;