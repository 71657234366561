import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { app, analytics } from './stores/firebase.jsx';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import Products from './components/pages/Products';
import Login from './components/pages/Login';
import Backoffice from './components/pages/Backoffice';
import AddCar from './components/admin/AddCar';
import CarPage from './components/pages/CarPage';
import Contacts from './components/pages/Contacts';
import Footer from './components/Footer';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import PrivateRoute from './components/PrivateRoute'; // Importe o PrivateRoute
import LoadingIndicator from './components/LoadingIndicator';
import UpdateCar from './components/admin/UpdateCar';
import UpdatePhotos from './components/admin/UpdateAdvert/UpdatePhotos';
import ChangePhotoOrder from './components/admin/UpdateAdvert/ChangePhotoOrder';
import AddRemovePhotos from './components/admin/UpdateAdvert/AddRemovePhotos';
import Local from './components/pages/Local'
import CentroArbitrario from './components/pages/CentroArbitrario';
import Servicos from './components/pages/Servicos';
import Garantia from './components/pages/Garantia';

import './styles/App.css';

const auth = getAuth(app);

function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in.
                setUser(user);
            } else {
                // User is signed out.
                setUser(null);
            }
        });

        setLoading(false);


        // Clean up the observer when the component unmounts    
        return () => unsubscribe();
    }, []);

    if (loading) {  
        // Show a loading indicator or some other content while authentication state is being resolved
        return <LoadingIndicator />;
    }

    return (
        <>
            <Navbar />
            <Routes>
                <Route path='/' exact element={<Home />} />
                <Route path='/products' exact element={<Products />} />
                <Route path='/login' exact element={<Login />} />
                <Route path='/Contactos' exact element={<Contacts />} />
                <Route path='/PrivacyPolicy' exact element={<PrivacyPolicy />} />
                <Route path='/car/:advertId' element={<CarPage />} />
                <Route path='/Localizacao' element={<Local />} />
                <Route path='/backoffice' element={<PrivateRoute element={<Backoffice />} user={user} />} />
                <Route path='/addcar' element={<PrivateRoute element={<AddCar />} user={user} />} />
                <Route path='/updatecar/:advertId' element={<PrivateRoute element={<UpdateCar />} user={user} />} />
                <Route path='/updatephotos/:advertId' element={<PrivateRoute element={<UpdatePhotos />} user={user} />} />
                <Route path='/change-photo-order/:advertId' element={<PrivateRoute element={<ChangePhotoOrder />} user={user} />} />
                <Route path='/add-remove/:advertId' element={<PrivateRoute element={<AddRemovePhotos />} user={user} />} />
                <Route path='/CentroArbitrario' element={<CentroArbitrario />} />
                <Route path='/Servicos' element={<Servicos />} />
                <Route path='/Garantia' element={<Garantia/>} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;