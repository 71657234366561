import React from 'react';
import '../../styles/App.css';
import '../../styles/Home.css';
import CardsDashboard from '../CardsDashboard';
import HeroSection from '../HeroSection';


function Home() {
    return (
        <>
            <HeroSection />
            <div className="home-wrapper">
                <div className="home-wrapper-content">
                    <div className="home-head">
                        <h1>Novidades em destaque!</h1>
                    </div>
                    <CardsDashboard />
                </div>
            </div>
        </>
    );
}

export default Home;