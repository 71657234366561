import React, { useState, useEffect } from 'react';
import ImageGallery from "react-image-gallery";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from '../../stores/firebase.jsx';
import { archiveAdverts, exportAdToFb, fetchAdvertById } from '../../stores/apiService';
import '../../styles/CustomSlider.css'; // Import your CSS file for styling
import '../../styles/CarPage.css'; // Import your CSS file for styling
import SuccessFailureMessage from '../admin/GenericComponents/SuccessFailureMessage';

const auth = getAuth(app);

function CarPage() {
    const { advertId } = useParams(); // Get the car ID from the URL

    const [user, setUser] = useState(null);

    const navigate = useNavigate();

    const [photos, setPhotos] = useState([{}]);

    const FuelTypeName = {
        0: "Gásoleo",
        1: "Gasolina",
        2: "Elétrico",
        3: "Híbrido Plug-In",
        4: "Híbrido",
        5: "GPL"
    }

    const Status = {
        0: 'Novo',
        1: 'Usado',
        2: 'Como novo',
        3: 'Reservado',
        4: 'Vendido/Arquivado'
    }

    const [carDetails, setCarDetails] = useState({
        Maker: '', // Update with the correct property names
        model: '',
        price: 0,
        features: [],
        CarCategory: '',
        CC: '',
        FuelType: '',
        HP: '',
        Color: '',
        Description: '',
        Mileage: '',
        Status: '',
        Year: '',
        ImageCount: ''
    });

    const [saveSuccess, setSaveSuccess] = useState(null); // null: não mostrando, true: sucesso, false: falha
    const [archived, setArchived] = useState(null);

    var images = [];

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in.
                setUser(user);
            } else {
                // User is signed out.
                setUser(null);
            }
        });

        // Clean up the observer when the component unmounts    

        fetchAdvertById(advertId)
            .then((fullAdvert) => {

                var photos = fullAdvert.Photos.$values;

                setCarDetails({
                    Maker: fullAdvert.Advert.Car.Maker,
                    model: fullAdvert.Advert.Car.Model,
                    price: fullAdvert.Advert.Price,
                    features: fullAdvert.Advert.CarFeatures.split(','),
                    CarCategory: fullAdvert.Advert.Car.CarCategory,
                    CC: fullAdvert.Advert.Car.CC,
                    FuelType: fullAdvert.Advert.Car.FuelType,
                    HP: fullAdvert.Advert.Car.HP,
                    Color: fullAdvert.Advert.Color,
                    Description: fullAdvert.Advert.Description,
                    Mileage: fullAdvert.Advert.Mileage,
                    Price: fullAdvert.Advert.Price,
                    Status: fullAdvert.Advert.Status,
                    Year: fullAdvert.Advert.Year,
                    ImageCount: fullAdvert.Advert.ImageCount
                });

                for (var i = 0; i < photos.length; i++) {
                    images.push({
                        original: '../images/' + photos[i].PhotoId + '.jpeg',
                        thumbnail: '../images/' + photos[i].PhotoId + '.jpeg',
                    });
                }

                setPhotos(images);
            })
            .catch((error) => {
                console.error('Error fetching car details:', error);
            });

        return () => unsubscribe();
    }, [advertId]);

    const handleEditClick = () => {
        //console.log("redirecting to update car advert");
        navigate(`/updateCar/${advertId}`);
    }

    const handleEditPhotosClick = () => {
        //console.log("redirecting to update car advert");
        navigate(`/updatePhotos/${advertId}`);
    }

    const handleExportFbClick = async () => {
        try {
            const exportToFbAdvert = await exportAdToFb(advertId);
            if (exportToFbAdvert === "Postagem publicada com sucesso!") {
                setSaveSuccess(true);
            }
            else {
                setSaveSuccess(false);
            }
        }
        catch (error) {
            setSaveSuccess(false);
            throw error;
        }
    }

    const handleArchiveClick = async () => {
        try {
            const archiveAdvert = await archiveAdverts(advertId);
            if (archiveAdvert === "Anúncio arquivado com sucesso!") {
                setSaveSuccess(true);
                setArchived(true);

            }
            else {
                setSaveSuccess(false);
            }

        }
        catch (error) {
            setSaveSuccess(false);
            throw error;
        };
    }

    return (
        <div>
            <SuccessFailureMessage success={saveSuccess} />
            <div className="car-page">
                {user && !archived ?
                    <div>
                        <button class="export-fb" onClick={handleExportFbClick}>
                            <i class="fas fa-edit edit-icon"></i> Exportar
                        </button>
                    </div>
                    : ''}
                {user && !archived ?
                    <div>
                        <button class="edit-button" onClick={handleEditClick}>
                            <i class="fas fa-edit edit-icon"></i> Editar
                        </button>
                    </div>
                    : ''}
                {user && !archived ?
                    <div>
                        <button class="edit-photo-button" onClick={handleEditPhotosClick}>
                            <i class="fas fa-edit edit-icon"></i> Editar Fotos
                        </button>
                    </div>
                    : ''}
                {user && !archived ?
                    <div>
                        <button class="archive-button" onClick={handleArchiveClick}>
                            <i class="fas fa-box-archive archive-icon"></i> Arquivar
                        </button>
                    </div>
                    : ''}
                <div className="car-photo">
                    <ImageGallery items={photos} style="max-width: 100%; display: inline-block;" />
                </div>
                <div className="car-info">
                    <div className="info-header">
                        <div className="brand">{carDetails.Maker}</div>
                        <div className="model">{carDetails.model}</div>
                        <div className="price">
                            {carDetails.price === 0 ? 'Sob consulta!' : carDetails.price === -1 ? 'Vendido!' : carDetails.price + ' €'}
                        </div>

                    </div>
                    <hr className="separator" />
                    <div className="features-grid">
                        <div className="Year">
                            <div className="spec">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-12V7h2v5h-2z" />
                                    </svg>
                                </div>
                                <div className="details">
                                    <div className="key-name">Registo</div>
                                    <div className="value">{carDetails.Year}</div>
                                </div>
                            </div>
                        </div>
                        <div className="Mileage">
                            <div className="spec">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                                        <path d="M256 32H181.2c-27.1 0-51.3 17.1-60.3 42.6L3.1 407.2C1.1 413 0 419.2 0 425.4C0 455.5 24.5 480 54.6 480H256V416c0-17.7 14.3-32 32-32s32 14.3 32 32v64H521.4c30.2 0 54.6-24.5 54.6-54.6c0-6.2-1.1-12.4-3.1-18.2L455.1 74.6C446 49.1 421.9 32 394.8 32H320V96c0 17.7-14.3 32-32 32s-32-14.3-32-32V32zm64 192v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32s32 14.3 32 32z" />
                                    </svg>
                                </div>
                                <div className="details">
                                    <div className="key-name">Quilometragem</div>
                                    <div className="value">{carDetails.Mileage + ' Km'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="CarCategory">
                            <div className="spec">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                        <path d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
                                    </svg>
                                </div>
                                <div className="details">
                                    <div className="key-name">Segmento</div>
                                    <div className="value">{carDetails.CarCategory}</div>
                                </div>
                            </div>
                        </div>
                        <div className="CC">
                            <div className="spec">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                        <path d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z" />
                                    </svg>
                                </div>
                                <div className="details">
                                    <div className="key-name">Cilindrada</div>
                                    <div className="value">{carDetails.CC + ' Cc'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="HP">
                            <div className="spec">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                                        <path d="M448 238.1V160h16l9.8 19.6c12.5 25.1 42.2 36.4 68.3 26c20.5-8.2 33.9-28 33.9-50.1V80c0-19.1-8.4-36.3-21.7-48H560c8.8 0 16-7.2 16-16s-7.2-16-16-16H480 448C377.3 0 320 57.3 320 128H224 203.2 148.8c-30.7 0-57.6 16.3-72.5 40.8C33.2 174.5 0 211.4 0 256v56c0 13.3 10.7 24 24 24s24-10.7 24-24V256c0-13.4 6.6-25.2 16.7-32.5c1.6 13 6.3 25.4 13.6 36.4l28.2 42.4c8.3 12.4 6.4 28.7-1.2 41.6c-16.5 28-20.6 62.2-10 93.9l17.5 52.4c4.4 13.1 16.6 21.9 30.4 21.9h33.7c21.8 0 37.3-21.4 30.4-42.1l-20.8-62.5c-2.1-6.4-.5-13.4 4.3-18.2l12.7-12.7c13.2-13.2 20.6-31.1 20.6-49.7c0-2.3-.1-4.6-.3-6.9l84 24c4.1 1.2 8.2 2.1 12.3 2.8V480c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V315.7c19.2-19.2 31.5-45.7 32-75.7h0v-1.9zM496 64a16 16 0 1 1 0 32 16 16 0 1 1 0-32z" />
                                    </svg>
                                </div>
                                <div className="details">
                                    <div className="key-name">Potência</div>
                                    <div className="value">{carDetails.HP + ' Cv'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="FuelType">
                            <div className="spec">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                        <path d="M32 64C32 28.7 60.7 0 96 0H256c35.3 0 64 28.7 64 64V256h8c48.6 0 88 39.4 88 88v32c0 13.3 10.7 24 24 24s24-10.7 24-24V222c-27.6-7.1-48-32.2-48-62V96L384 64c-8.8-8.8-8.8-23.2 0-32s23.2-8.8 32 0l77.3 77.3c12 12 18.7 28.3 18.7 45.3V168v24 32V376c0 39.8-32.2 72-72 72s-72-32.2-72-72V344c0-22.1-17.9-40-40-40h-8V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64zM96 80v96c0 8.8 7.2 16 16 16H240c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16z" />
                                    </svg>
                                </div>
                                <div className="details">
                                    <div className="key-name">Combustível</div>
                                    <div className="value">{FuelTypeName[carDetails.FuelType]}</div>
                                </div>
                            </div>
                        </div>
                        <div className="Color">
                            <div className="spec">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                        <path d="M96 32v96H224V32c0-17.7-14.3-32-32-32H128C110.3 0 96 14.3 96 32zm0 128c-53 0-96 43-96 96V464c0 26.5 21.5 48 48 48H272c26.5 0 48-21.5 48-48V256c0-53-43-96-96-96H96zm64 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160zM384 48c0-1.4-1-3-2.2-3.6L352 32 339.6 2.2C339 1 337.4 0 336 0s-3 1-3.6 2.2L320 32 290.2 44.4C289 45 288 46.6 288 48c0 1.4 1 3 2.2 3.6L320 64l12.4 29.8C333 95 334.6 96 336 96s3-1 3.6-2.2L352 64l29.8-12.4C383 51 384 49.4 384 48zm76.4 45.8C461 95 462.6 96 464 96s3-1 3.6-2.2L480 64l29.8-12.4C511 51 512 49.4 512 48c0-1.4-1-3-2.2-3.6L480 32 467.6 2.2C467 1 465.4 0 464 0s-3 1-3.6 2.2L448 32 418.2 44.4C417 45 416 46.6 416 48c0 1.4 1 3 2.2 3.6L448 64l12.4 29.8zm7.2 100.4c-.6-1.2-2.2-2.2-3.6-2.2s-3 1-3.6 2.2L448 224l-29.8 12.4c-1.2 .6-2.2 2.2-2.2 3.6c0 1.4 1 3 2.2 3.6L448 256l12.4 29.8c.6 1.2 2.2 2.2 3.6 2.2s3-1 3.6-2.2L480 256l29.8-12.4c1.2-.6 2.2-2.2 2.2-3.6c0-1.4-1-3-2.2-3.6L480 224l-12.4-29.8zM448 144c0-1.4-1-3-2.2-3.6L416 128 403.6 98.2C403 97 401.4 96 400 96s-3 1-3.6 2.2L384 128l-29.8 12.4c-1.2 .6-2.2 2.2-2.2 3.6c0 1.4 1 3 2.2 3.6L384 160l12.4 29.8c.6 1.2 2.2 2.2 3.6 2.2s3-1 3.6-2.2L416 160l29.8-12.4c1.2-.6 2.2-2.2 2.2-3.6z" />
                                    </svg>
                                </div>
                                <div className="details">
                                    <div className="key-name">Cor</div>
                                    <div className="value">{carDetails.Color}</div>
                                </div>
                            </div>
                        </div>
                        <div className="Description">
                            <div className="spec">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                        <path d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z" />
                                    </svg>
                                </div>
                                <div className="details">
                                    <div className="key-name">Descrição</div>
                                    <div className="value">{carDetails.Description}</div>
                                </div>
                            </div>
                        </div>
                        <div className="Status">
                            <div className="spec">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                        <path d="M64 464c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm56 256c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120z" />
                                    </svg>
                                </div>
                                <div className="details">
                                    <div className="key-name">Estado</div>
                                    <div className="value">{Status[carDetails.Status]}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="separator" />
                    <div className="spec-separator-text">{'Características'}</div>
                    <div className="features-grid">
                        {carDetails.features.map((feature, index) => (
                            <div key={index} className="spec">
                                {feature}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarPage;
