import React, { useState, useEffect } from 'react';
import { getPhotos, uploadPhotos, removePhotos } from '../../../stores/apiService.js'; // Ajuste o caminho de importação conforme necessário
import { Link, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import SuccessFailureMessage from '../GenericComponents/SuccessFailureMessage';
import '../../../styles/UploadPhotos.css';

const AddRemovePhotos = () => {
    const { advertId } = useParams();

    var images = [];

    const [photos, setPhotos] = useState([]); // Store existing photos
    const [selectedPhotos, setSelectedPhotos] = useState([]); // Store selected photos
    const [newPhotos, setNewPhotos] = useState([]); // Store newly uploaded photos
    const [saveSuccess, setSaveSuccess] = useState(null); // null: não mostrando, true: sucesso, false: falha


    // Function to load existing photos (replace with actual API call)
    const loadPhotos = async () => {
        try {
            var items = await getPhotos(advertId);

            for (var i = 0; i < items.length; i++) {
                images.push({
                    id: items[i].photoId,
                    url: '../images/' + items[i].photoId + '.jpeg',
                });
            }

            setPhotos(images);
        }
        catch (error) {
            console.error('Error fetching photos:', error);
        }
    };

    useEffect(() => {
        loadPhotos();
    }, []);

    // Function to handle file drop for uploading new photos
    const onDrop = async (acceptedFiles) => {
        //console.log("on drop!");
        try {
            await uploadPhotos(acceptedFiles, advertId);

            await loadPhotos();
            setSaveSuccess(true);

        }
        catch (error) {
            console.error('Error uploading photos:', error);
            setSaveSuccess(false);

        }

    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleSelectAll = () => {
        setSelectedPhotos(photos);
    };

    const handleDeselectAll = () => {
        setSelectedPhotos([]);
    };

    // Function to handle photo selection
    const togglePhotoSelection = (photo) => {
        if (selectedPhotos.includes(photo)) {
            setSelectedPhotos(selectedPhotos.filter((selected) => selected !== photo));
        } else {
            setSelectedPhotos([...selectedPhotos, photo]);
        }
    };

    // Function to delete selected photos (replace with actual API call)
    const deleteSelectedPhotos = async () => {
        if (selectedPhotos.length === 0) {
            return; // No photos selected for deletion
        }

        //console.log("remove photos selectedphoto object: " + JSON.stringify(selectedPhotos));

        var removeList = [];

        for (var i = 0; i < selectedPhotos.length; i++) {
            //console.log("inside loop: " + selectedPhotos[i].id);
            removeList.push(
                selectedPhotos[i].id
            );
        }

        var updatePhotos = {
            AdvertId: advertId,
            RemovedPhotos: removeList
        };

        //console.log("Remove photos before the request: " + JSON.stringify(updatePhotos));

        var updated = removePhotos(updatePhotos);


        if (updated != null) {
            // Remove the deleted photos from the 'photos' state
            setPhotos(photos.filter((photo) => !selectedPhotos.includes(photo)));
            setSelectedPhotos([]); // Clear selected photos
            setSaveSuccess(true);

        } else {
            // Handle error
            //console.error('Failed to delete photos:', updated.statusText);
            setSaveSuccess(false);

        }
    };

    return (
        <div className="container col-md-12">
            <h1 className="title md-4">Adicionar ou Remover Fotos</h1>
            <SuccessFailureMessage success={saveSuccess} />
            <div>
                {/* Dropzone for uploading new photos */}
                <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <p>Araste as fotos para esta área ou click aqui para selcionar as fotos!</p>
                </div>
            </div>
            <div>
                <div>
                    {/* Display existing photos as thumbnails */}
                    <div style={thumbnailContainerStyles}>
                        {photos.map((photo) => (
                            <div key={photo.id} style={thumbnailStyles}>
                                <img
                                    src={photo.url}
                                    alt={photo.name}
                                    onClick={() => togglePhotoSelection(photo)}
                                    style={thumbnailImageStyles}
                                />
                                <input
                                    type="checkbox"
                                    style={{ width: '20px', height: '20px' }}
                                    checked={selectedPhotos.includes(photo)}
                                    onChange={() => togglePhotoSelection(photo)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="text-center col-md-6">
                    <div className="row">
                        <button className="btn btn-success btn-lg mb-3" onClick={handleSelectAll}>Selecionar todas as fotos</button>
                    </div>
                    <div className="row">
                        <button className="btn btn-primary btn-lg mb-3" onClick={handleDeselectAll}>Deselecionar todas as fotos</button>
                    </div>
                    <div className="row">
                        <button className="btn btn-danger btn-lg mb-3" onClick={deleteSelectedPhotos}>Apagar fotos</button>
                    </div>
                    <div className="row">
                        <Link to={`/UpdatePhotos/${advertId}`} className="btn btn-secondary btn-lg">Voltar</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

const thumbnailContainerStyles = {
    display: 'flex',
    flexWrap: 'wrap',
};

const thumbnailStyles = {
    width: '150px',
    margin: '8px',
    border: '1px solid #ccc',
    padding: '4px',
    borderRadius: '4px',
};

const thumbnailImageStyles = {
    maxWidth: '100%',
    maxHeight: '100%',
    cursor: 'pointer',
};

export default AddRemovePhotos;
