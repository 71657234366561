import React from 'react';
import '../styles/App.css';
import '../styles/HeroSection.css';

function HeroSection() {
    return (
        <div className="landing-page">
            <img src="../images/static/background.png" alt="" />
        </div>
  );
}

export default HeroSection;