import React from 'react';
import '../../styles/PolicyPage.css';

function PrivacyPolicy() {
    return (
        <div className="policy-page">
            <div className="policy-content">

                <h1>Política de Utilização de Dados e Privacidade</h1>

                <p>
                    Bem-vindo ao nosso portal! Valorizamos a sua privacidade e queremos garantir que você tenha uma experiência segura e confiável enquanto navega em nosso site. Nesta página, explicaremos como tratamos a privacidade dos nossos utilizadores, mesmo que, atualmente, não recolhamos ou processemos informações pessoais.
                </p>

                <h2>1. Não Recolhemos Informações Pessoais:</h2>

                <p>
                    Neste momento, o nosso portal não recolhe qualquer informação pessoal dos utilizadores, como nomes, endereços de email, números de telefone ou outros dados identificáveis. Não fazemos uso de cookies ou outras tecnologias de rastreamento para coletar dados pessoais.
                </p>

                <h2>2. Dados de Navegação:</h2>

                <p>
                    Embora não recolhamos informações pessoais, é importante mencionar que podemos recolher informações de caráter técnico, como endereços de IP, navegadores utilizados e páginas visitadas. Esses dados são coletados apenas para fins de análise e melhoria do desempenho do nosso portal.
                </p>

                <h2>3. Privacidade e Segurança:</h2>

                <p>
                    Apesar de não tratarmos dados pessoais, a sua privacidade e segurança são importantes para nós. Implementamos medidas de segurança para proteger os nossos utilizadores e garantir a integridade do nosso portal.
                </p>

                <h2>4. Alterações Futuras:</h2>

                <p>
                    É importante observar que a nossa política de privacidade pode ser atualizada no futuro, caso decidamos recolher informações pessoais para melhorar a sua experiência no portal. Quando isso ocorrer, atualizaremos esta política para refletir as mudanças e garantiremos total transparência.
                </p>

                <h2>5. Questões e Contacto:</h2>

                <p>
                    Se tiver alguma dúvida ou preocupação sobre a nossa política de privacidade ou sobre o uso do nosso portal, por favor, entre em contacto connosco através
                    eppautomoveis@gmail.com.
                </p>

                <p>
                    Esta política de utilização de dados e privacidade está em conformidade com as regulamentações de proteção de dados da União Europeia, incluindo o Regulamento Geral de Proteção de Dados (RGPD).
                </p>

                <p>
                    Agradecemos por escolher o nosso portal e confiar na nossa abordagem transparente e responsável em relação à privacidade. Se decidirmos recolher informações no futuro, comprometemo-nos a proteger e tratar esses dados com o máximo cuidado e respeito pela sua privacidade.
                </p>
                <p>
                    Atualizado em:
                    02/09/2023
                </p>
                {/*<ul>*/}

                {/*    <li>*/}
                {/*        <span className="bullet">&#8226;</span> Melhorar a Experiência do Utilizador: Os cookies ajudam-nos a personalizar a sua experiência no nosso website, lembrando as suas preferências, como o idioma escolhido ou a região onde se encontra.*/}
                {/*    </li>*/}

                {/*    <li>*/}
                {/*        <span className="bullet">&#8226;</span> Análise e Estatísticas: Utilizamos cookies para recolher dados sobre como os visitantes interagem com o nosso website. Estas informações são valiosas para melhorar o conteúdo e a funcionalidade do site.*/}
                {/*    </li>*/}

                {/*    <li>*/}
                {/*        <span className="bullet">&#8226;</span> Publicidade Direcionada: Os cookies podem ser usados para apresentar anúncios mais relevantes aos visitantes, com base nos seus interesses e comportamento de navegação.*/}
                {/*    </li>*/}

                {/*</ul>*/}

            </div>
        </div>
    );
}

export default PrivacyPolicy;